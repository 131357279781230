import saMessages from "../locales/nl_NL.json"

const nlLang = {
    messages: {
        ...saMessages
    },
    locale: "nl"
}

export default nlLang
