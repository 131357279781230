import React from "react"
import JSON5 from "json5"
import axios from "./config"

// Local Imports
import ProspectsAPI from "../../api/prospects"
import handleFooterData, {initFooterData} from "../../common"
import {setItemsPerPage} from "../../../redux/actions/contactApp"
import {fetchError, fetchStart, fetchSuccess} from "../../../redux/actions"
import {setAuthUser, setForgetPassMailSent, updateLoadUser} from "../../../redux/actions/Auth"

const JWTAuth = {
    onRegister: ({name, email, password}) => {
        return dispatch => {
            dispatch(fetchStart())
            axios
                .post("auth/register", {
                    email: email,
                    password: password,
                    name: name
                })
                .then(({data}) => {
                    if (data.result) {
                        localStorage.setItem("token", data.token.access_token)
                        axios.defaults.headers.common["Authorization"] = "Bearer " + data.token.access_token
                        dispatch(fetchSuccess())
                        dispatch(JWTAuth.getAuthUser(true, data.token.access_token))
                    } else {
                        dispatch(fetchError(data.error));
                    }
                })
                .catch(function (error) {
                    dispatch(fetchError(error.message))
                })
        }
    },
    onLogin: ({email, password}) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post("auth/login", {
                        email: email,
                        password: password
                    })
                    .then(({data}) => {
                        if (data.result) {
                            localStorage.setItem("token", data.token.access_token);
                            axios.defaults.headers.common["Authorization"] =
                                "Bearer " + data.token.access_token;
                            dispatch(fetchSuccess());
                            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
                        } else {
                            dispatch(fetchError(data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.message));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    onLogout: () => {
        return dispatch => {
            dispatch(fetchStart());
            axios
                .post("auth/logout")
                .then(({data}) => {
                    if (data.result) {
                        dispatch(fetchSuccess());
                        localStorage.removeItem("token");
                        localStorage.removeItem("templateSettings");
                        dispatch(setAuthUser(null));
                    } else {
                        dispatch(fetchError(data.error));
                    }
                })
                .catch(function (error) {
                    dispatch(fetchError(error.message));
                });
        };
    },
    getAuthUser: (loaded = false) => {
        return dispatch => {
            dispatch(fetchStart())
            dispatch(updateLoadUser(loaded))

            const url = new URL(window.location.href)
            const user_key = url.searchParams.get("user_key") === null ? "" : url.searchParams.get("user_key")
            const user_api = url.searchParams.get("user_api") === null ? "" : url.searchParams.get("user_api")

            axios
                .request({
                    method: "GET",
                    params: {
                        action: "get_token",
                        user_key: user_key
                    }
                })
                .then(({data}) => {
                    let authData = data.response

                    authData.user_key = user_key
                    authData.user_api = user_api

                    if (data.msg === "authorised") {
                        // GET Settings
                        axios
                            .request({
                                method: "GET",
                                params: {
                                    action: "get_settings",
                                    t: authData["token"],
                                    u: 0
                                }
                            })
                            .then(({data}) => {
                                let response = data.response
                                initFooterData(response, dispatch, false)

                                let settings = {...response}

                                authData.settings = JSON5.stringify(settings)
                                let website = settings.website

                                let menuStart = settings["menuStart"]
                                if (menuStart === "prospects") {
                                    ProspectsAPI
                                        .init({token: authData["token"]})
                                        .then((data) => {
                                            let response = data.response

                                            settings = {...settings, ...response}
                                            authData.settings = JSON5.stringify(settings)
                                        })
                                        .then(() => {
                                            let orderId = settings["prospects_section_1"]
                                            if (orderId.startsWith("'.")) orderId = orderId.replace("'.", "")
                                            if (orderId.endsWith(".'")) orderId = orderId.replace(".'", "")

                                            settings["prospects_section_1"] = orderId

                                            if (settings["prospects_steps"].length === 1)
                                                dispatch(setItemsPerPage(9))
                                            else
                                                dispatch(setItemsPerPage(6))

                                            ProspectsAPI
                                                .updatePrice(authData["token"], settings["prospects_section_1"], "basic", "start")
                                                .then((data) => {
                                                    dispatch(setAuthUser({
                                                        authData: authData,
                                                        token: authData["token"],
                                                        orderID: settings["prospects_section_1"],
                                                        config: data,
                                                        baseWebsite: website,
                                                        settings: authData.settings
                                                    }))

                                                    if (data?.order?.footer)
                                                        handleFooterData(data["order"]["footer"], dispatch, false)
                                                })
                                        })
                                        .catch((err) => console.log(err))
                                } else {
                                    dispatch(setAuthUser({
                                        authData: authData,
                                        token: authData["token"],
                                        orderID: settings["prospects_section_1"],
                                        config: null,
                                        baseWebsite: website,
                                        settings: authData.settings
                                    }))
                                }
                            })
                    } else dispatch(updateLoadUser(true))
                })
                .catch(function (error) {
                    dispatch(updateLoadUser(true))
                })
                .finally(() => dispatch(fetchSuccess()))
        }
    },
    onForgotPassword: () => {
        return dispatch => {
            dispatch(fetchStart())

            setTimeout(() => {
                dispatch(setForgetPassMailSent(true))
                dispatch(fetchSuccess())
            }, 300)
        }
    },
    getSocialMediaIcons: () => {
        return <React.Fragment> </React.Fragment>;
    }
}

export default JWTAuth
