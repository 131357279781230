import {
    UPDATE_FOOTER,
    UPDATE_FOOTER_TABLE_STATE,
    UPDATE_FOOTER_ONLY_PROSPECT,
    UPDATE_PROSPECT_CURRENT_STEP
} from "../../@jumbo/constants/ActionTypes"

const INIT_STATE = {
    footer: {
        footerLogoText: "",
        footerText: "",
        footerButtonText: "",
        footerButtonURL: "",
        footerValue: "0",
        footerButtonMsg: "",
        prospect: {
            count: "",
            amountEx: "",
            vatText: "",
            amountVAT: "",
            totalText: "",
            amountInc: "",
            buttonText: "",
            buttonURL: ""
        },
        enablePayButton: false
    },
    openTable: false,
    currentStep: ""
}

export default (state = INIT_STATE, action) => {
    let initFooter = state.footer

    switch (action.type) {
        case UPDATE_FOOTER: {
            return {
                ...state,
                footer: {
                    ...state.footer,
                    ...action.payload
                }
            };
        }
        case UPDATE_FOOTER_ONLY_PROSPECT: {
            return {
                ...state,
                footer: {
                    ...initFooter,
                    prospect: action.payload
                }
            };
        }
        case UPDATE_FOOTER_TABLE_STATE: {
            return {
                ...state,
                openTable: action.payload
            }
        }
        case UPDATE_PROSPECT_CURRENT_STEP: {
            return {
                ...state,
                currentStep: action.payload
            }
        }
        default:
            return state
    }
}
