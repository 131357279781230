import enMessages from "../locales/en_US.json"

const nlEnLang = {
    messages: {
        ...enMessages
    },
    locale: "en-NL"
}

export default nlEnLang
