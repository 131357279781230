import enMessages from "../locales/fr_LU.json"

const frLULang = {
    messages: {
        ...enMessages
    },
    locale: "fr-LU"
}

export default frLULang
