import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router";
import PageLoader from "../../@jumbo/components/PageComponents/PageLoader";

const Dashboards = ({match}) => {
    const requestedUrl = match.url.replace(/\/$/, "");

    return (
        <Suspense fallback={<PageLoader/>}>
            <Switch>
                <Redirect
                    exact
                    from={`${requestedUrl}/`}
                    to={`${requestedUrl}/competitive-research`}
                />
                <Route
                    path={`${requestedUrl}/competitive-research`}
                    component={lazy(() => import("./CompetitiveResearchApp"))}
                />
                {/*<Route*/}
                {/*  path={`${requestedUrl}/contact`}*/}
                {/*  component={lazy(() => import("./ContactApp"))}*/}
                {/*/>*/}
                <Route component={lazy(() => import("../ExtraPages/404"))}/>
            </Switch>
        </Suspense>
    );
};

export default Dashboards;
