import PropTypes from "prop-types"
import {setFooter, setFooterOnlyProspect, setFooterTableState} from "../redux/actions/Footer"

export function initFooterData(data, dispatch, setTableState) {
    let footerData = {
        footerLogoText: data["footer_logo_text"],
        footerText: data["footer_text"],
        footerButtonMsg: data["footer_button_msg"]
    }

    dispatch(setFooter(footerData))
    if (setTableState !== null)
        dispatch(setFooterTableState(setTableState))
}

export default function handleFooterData(data, dispatch, setTableState) {
    if (data){
        const prospectData = data["prospects_counts"]

        let prospect = {
            count: prospectData["count"],
            amountEx: prospectData["amount_ex"],
            vatText: prospectData["vat_text"],
            amountVAT: prospectData["amount_vat"],
            totalText: prospectData["total_text"],
            amountInc: prospectData["amount_inc"],
            buttonText: prospectData["button_text"],
            buttonURL: prospectData["button_url"]
        }

        dispatch(setFooterOnlyProspect(prospect))
        if (setTableState !== null)
            dispatch(setFooterTableState(setTableState))
    }
}

handleFooterData.propTypes = {
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    setTableState: PropTypes.bool.isRequired
}
