import {SET_ENRICH_DATA} from "../../@jumbo/constants/ActionTypes"

const INIT_STATE = {
    msg: "",
    msgText: "",
    enrichId: "",
    percentage: "",
    error: "",
    runtime: "",
    closeOnUpload: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ENRICH_DATA: {
            return {
                ...state,
                ...action.payload
            }
        }

        default:
            return state
    }
}
