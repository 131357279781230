import React from 'react'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import {makeStyles} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiBadge-badge": {
            [theme.breakpoints.up("sm")]: {
                minWidth: 30,
                height: 30,
                fontSize: ".9rem",
                borderRadius: 20
            },
            backgroundColor: "#1DBF73",
            color: "#FFFFFF"
        }
    }
}))

const FooterBadge = ({content}) => {
    const classes = useStyles()

    return (
        <IconButton className={classes.root} aria-label="cart">
            <Badge
                max={10000000000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                badgeContent={content}
                color="secondary"
            >
                <ShoppingCartIcon
                    fontSize="large"
                />
            </Badge>
        </IconButton>
    )
}

export default FooterBadge
