import XLSX from "xlsx"
import {useEffect, useState} from "react"

import {
    Austria,
    Belgium,
    CzechRepublic,
    Denmark,
    Finland,
    France,
    Germany,
    Greece,
    Hungary,
    Ireland,
    Italy,
    Latvia,
    Liechtenstein,
    Lithuania,
    Luxembourg,
    Netherlands,
    Norway,
    Poland,
    Portugal,
    Romania,
    Slovakia,
    Slovenia,
    Spain,
    Sweden,
    Switzerland,
    UK
} from "../packages/svg-map/maps/index"

export const getSVGMap = (countryCode) => {
    let newSVGMap = null

    switch (countryCode) {
        case "AT":
            newSVGMap = Austria
            break
        case countryCode === "BE":
            newSVGMap = Belgium
            break
        case "CZ":
            newSVGMap = CzechRepublic
            break
        case "DK":
            newSVGMap = Denmark
            break
        case "FI":
            newSVGMap = Finland
            break
        case "FR":
            newSVGMap = France
            break
        case "GR":
            newSVGMap = Greece
            break
        case "HU":
            newSVGMap = Hungary
            break
        case "IT":
            newSVGMap = Italy
            break
        case "LU":
            newSVGMap = Luxembourg
            break
        case "NL":
            newSVGMap = Netherlands
            break
        case "SE":
            newSVGMap = Sweden
            break
        case "UK":
            newSVGMap = UK
            break
        case "CH":
            newSVGMap = Switzerland
            break
        case "DE":
            newSVGMap = Germany
            break
        case "LV":
            newSVGMap = Latvia
            break
        case "LT":
            newSVGMap = Lithuania
            break
        case "PL":
            newSVGMap = Poland
            break
        case "PT":
            newSVGMap = Portugal
            break
        case "SI":
            newSVGMap = Slovenia
            break
        case "LI":
            newSVGMap = Liechtenstein
            break
        case "ES":
            newSVGMap = Spain
            break
        case "RO":
            newSVGMap = Romania
            break
        case "SK":
            newSVGMap = Slovakia
            break
        case "IE":
            newSVGMap = Ireland
            break
        case "NO":
            newSVGMap = Norway
            break
        default:
            newSVGMap = null
    }

    return newSVGMap
}

/**
 * Calculates the x-axis value of the center of the marker on a SVG Map
 * @param long Longitude of the place
 * @param a Leftmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param c Rightmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param width Width value from the viewBox variable of the SVG Map
 * @returns {number}
 */
export const calculateMarkerX = (long, a, c, width) => Math.floor(((long - a) / (c - a)) * width)

/**
 * Calculates the y-axis value of the center of the marker on a SVG Map
 * @param lat Latitude of the place
 * @param b Topmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param d Bottommost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param height Height value from the viewBox variable of the SVG Map
 * @returns {number}
 */
export const calculateMarkerY = (lat, b, d, height) => Math.floor(((lat - b) / (d - b)) * height)

export const calculateSVGTextDx = ({zoomLevel = 0}) => {
    let dx

    switch (zoomLevel) {
        case 7:
            dx = "0.05em"
            break
        case 8:
            dx = "0.1em"
            break
        case 9:
            dx = "0.125em"
            break
        case 10:
            dx = "0.15em"
            break
        case 11:
            dx = "0.175em"
            break
        case 12:
            dx = "0.2em"
            break
        default:
            dx = "0em"
    }

    return dx
}

/**
 * Calculates the length of a country/place in Kilometers from the latitude value
 * @param b Topmost latitude of the country/place (From geoViewBox variable of the SVG Map)
 * @param d Bottommost latitude of the country/place (From geoViewBox variable of the SVG Map)
 * @returns {number}
 */
export const calculateHeightFromLatitude = (b, d) => Math.floor(Math.abs(b - d) * 69.44 * 1.61)

/**
 *
 * @param radius Actual radius in Kilometers
 * @param b Topmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param d Bottommost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param height Height value from the viewbox variable of the SVG Map
 * @returns {number}
 */
export const calculateMarkerRadiusInPixels = (radius, b, d, height) => (height / calculateHeightFromLatitude(b, d)) * radius

/**
 * Calculate the x-axis value of the top-left corner of the Zoom Box
 * @param long Longitude of the place
 * @param a Leftmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param c Rightmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param width Width value from the viewBox variable of the SVG Map
 * @returns {number}
 */
export const calculateZoomBoxX = (long, a, c, width) => Math.floor(((long - a) / (c - a)) * width)

/**
 * Calculates the y-axis value of the center of the marker on a SVG Map
 * @param lat Latitude of the place
 * @param b Topmost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param d Bottommost longitude of the country that the place belongs to (From geoViewBox variable of the SVG Map)
 * @param height Height value from the viewBox variable of the SVG Map
 * @returns {number}
 */
export const calculateZoomBoxY = (lat, b, d, height) => Math.floor(((lat - b) / (d - b)) * height)

/**
 *
 * @param menuOption
 * @returns {string}
 */
export const getInternalURLFromMenuOption = (menuOption) => {
    let url = ""

    switch (menuOption) {
        case "home":
            url = "/home"
            break
        case "trends":
            url = "/extra-pages/trends"
            break
        case "import":
            url = "/extra-pages/import"
            break
        case "export":
            url = "/extra-pages/export"
            break
        case "crm":
            url = "/extra-pages/crm"
            break
        case "enrich":
            url = "/extra-pages/enrich"
            break
        case "smc":
            url = "/extra-pages/smc"
            break
        case "oct":
            url = "/extra-pages/oct"
            break
        case "cct":
            url = "/extra-pages/cct"
            break
        case "mpa":
            url = "/extra-pages/mpa"
            break
        case "cst":
            url = "/extra-pages/cst"
            break
        case "profiling":
            url = "/extra-pages/profiling"
            break
        case "forms":
            url = "/extra-pages/forms"
            break
        case "prospects":
            url = "/data/prospects"
            break
        case "updates":
            url = "/extra-pages/updates"
            break
        case "profile":
            url = "/extra-pages/manage-profile"
            break
        case "users":
            url = "/extra-pages/manage-users"
            break
        case "orders":
            url = "/extra-pages/view-orders"
            break
        case "logout":
            url = "/extra-pages/error-454"
            break
        case "handbook":
            url = "/extra-pages/handbook"
            break
        case "tool":
            url = "/extra-pages/support-tool"
            break
        case "data":
            url = "/extra-pages/support-data"
            break
        case "finance":
            url = "/extra-pages/support-finance"
            break
        case "chat":
            url = "/extra-pages/chat"
            break
        case "contact":
            url = "/extra-pages/contact"
            break
        default:
            url = ""
    }

    return url
}

export const filterRadiusFromCityId = cityId => {
    if (cityId.includes("R"))
        return parseInt(cityId.split("R")[1])
    else return parseInt(cityId.split("r")[1])
}

/**
 * This function checks IF a LocalSEO entry is a new entry or not
 * @param percentage
 * @param folder
 * @returns {boolean}
 */
export const isNewLocalSEOEntry = ({
                                       percentage,
                                       folder
                                   }) => percentage !== "" && !isNaN(Number(percentage)) && Number(percentage) < 100 && folder !== "inactive"

export const sortLocalSEOAccounts = (localSEOAccounts) => {
    localSEOAccounts
        .sort(
            (x, y) => "highlight" in x && x.highlight === true ? -1
                : "highlight" in y && y.highlight === true ? 1
                    : parseInt(x.percentage) < parseInt(y.percentage) ? -1
                        : parseInt(y.percentage) < parseInt(x.percentage) ? 1
                            : parseInt(x.id) > parseInt(y.id) ? -1
                                : parseInt(y.id) > parseInt(x.id) ? 1
                                    : x.name.toLowerCase() < y.name.toLowerCase() ? -1
                                        : y.name.toLowerCase() < x.name.toLowerCase() ? 1
                                            : 0
        )

    return localSEOAccounts
}

/**
 * This function adds a count field in the `foldersList`
 * @param foldersList - folders list
 * @param accountList - latest accounts list
 * @returns {*}
 */
export const buildFoldersList = (foldersList, accountList) => {
    foldersList
        .map(
            x => x.count = accountList.filter(
                y => y.folder === x.slug || (x.slug in y && y[x.slug] === true)
            ).length
        )

    return foldersList
}

/**
 * This function adds a count field in the `labelsList`
 * @param labelsList - labels list
 * @param accountList - latest accounts list
 * @returns {*}
 */
export const buildLabelsList = (labelsList, accountList) => {
    labelsList
        .map(
            x => x.count = accountList.filter(y => y.labels.includes(x.id)).length
        )

    return labelsList
}

export const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window
    return {
        width,
        height
    }
}

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
}

export const ExportToExcel = (fileName, headers, data) => {
    fileName = fileName.endsWith(".xlsx") ? fileName : fileName + ".xlsx"

    const formattedDATA = data.map(item => {
        let _data = {}
        item.forEach((cell, index) => _data[headers[index]] = cell)

        return _data
    })

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(formattedDATA)
    XLSX.utils.book_append_sheet(workbook, worksheet, "data")
    XLSX.writeFileXLSX(workbook, fileName, {compression: true})
}
