import {UPDATE_USER_SETTINGS, UPDATE_EXPORT_SETTINGS_FOR_CUSTOM} from "../../@jumbo/constants/ActionTypes"

const INIT_STATE = {
    settings: null,
    exportSettingsForCustom: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_USER_SETTINGS: {
            return {
                ...state,
                settings: action.payload
            }
        }
        case UPDATE_EXPORT_SETTINGS_FOR_CUSTOM: {
            return {
                ...state,
                exportSettingsForCustom: action.payload
            }
        }
        default:
            return state
    }
}
