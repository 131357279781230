import React from "react"
import {useSelector} from "react-redux"
import {useLocation} from "react-router-dom"
import "react-notifications/lib/notifications.css"
import {Redirect, Route, Switch} from "react-router"
import {NotificationContainer} from "react-notifications"
import AppContext from "../@jumbo/components/contextProvider/AppContextProvider/AppContext"

// Local Imports
import Main from "./Main"
import Apps from "./Apps"
import Tools from "./Tools"
import DataSection from "./Data"
import Login from "./Auth/Login"
import TourGuide from "./TourGuide"
import Signup from "./Auth/Register"
import Dashboards from "./Dashboards"
import ExtraPages from "./ExtraPages"
import ForgotPassword from "./Auth/ForgotPassword"

const RestrictedRoute = ({component: Component, ...rest}) => {
    const {authUser} = useSelector(({auth}) => auth)
    const {dynamicNavigation} = useSelector(({navigation}) => navigation)
    const {showIFrame} = useSelector(({navigation}) => navigation)

    const renderIFrame = () => {
        let navigationType = dynamicNavigation["navigationType"]
        let value = dynamicNavigation["value"]

        return navigationType === "iframe" && value && showIFrame
    }

    return (
        <Route
            {...rest}
            render={props =>
                authUser ? (
                    <>
                        {
                            renderIFrame() ?
                                <>
                                    <div
                                        style={{width: "100%", height: "100%"}}
                                        dangerouslySetInnerHTML={{__html: dynamicNavigation["value"]}}
                                    />
                                </>
                                :
                                <Component {...props} />
                        }
                    </>
                ) : (
                    (window.location.href = "/unauthorized.html")
                )}
        />
    )
}

const Routes = () => {
    const location = useLocation()

    const JSON5 = require("json5")

    const {authUser} = useSelector(({auth}) => auth)
    const {updateThemeColors, sidebarStyle, updateSidebarStyle} = React.useContext(AppContext)

    let settings = null
    let menuStart = ""
    let themeColor = null

    if (authUser) {
        settings = authUser["settings"]
        if (settings) {
            settings = JSON5.parse(settings)

            menuStart = settings["menuStart"]
            themeColor = settings["themeColor"]
        }
    }

    React.useEffect(() => {
        if (themeColor) {
            let color = {
                backgroundColor: themeColor["sidebar"],
                textColor: "rgba(255, 255, 255, 0.8)",
                textDarkColor: "#fff",
                textActiveColor: "#000",
                navHoverBgColor: "rgba(255, 255, 255, 0.3)",
                navActiveBgColor: "#fff",
                borderColor: "rgba(255, 255, 255, 0.1)"
            }

            updateSidebarStyle({
                ...color,
                backgroundImage: sidebarStyle.backgroundImage
            })

            updateThemeColors({
                primary: themeColor["primary"],
                secondary: themeColor["secondary"]
            })
        }
    }, [])

    if (authUser && (location.pathname === "" || location.pathname === "/")) {
        if (menuStart === "home") return <Redirect to={"/home"}/>
        else if (menuStart === "trends")
            return <Redirect to={"/extra-pages/trends"}/>
        else if (menuStart === "import") return <Redirect to={"/extra-pages/import"}/>
        else if (menuStart === "export") return <Redirect to={"/extra-pages/export"}/>
        else if (menuStart === "crm") return <Redirect to={"/extra-pages/crm"}/>
        else if (menuStart === "enrich") return <Redirect to={"/tools/enrich"}/>
        else if (menuStart === "smc") return <Redirect to={"/extra-pages/smc"}/>
        else if (menuStart === "oct") return <Redirect to={"/extra-pages/oct"}/>
        else if (menuStart === "cct")
            return <Redirect to={"/apps/competitive-research"}/>
        else if (menuStart === "mpa") return <Redirect to={"/extra-pages/mpa"}/>
        else if (menuStart === "cst") return <Redirect to={"/extra-pages/cst"}/>
        else if (menuStart === "profiling")
            return <Redirect to={"/extra-pages/users"}/>
        else if (menuStart === "forms")
            return <Redirect to={"/extra-pages/forms"}/>
        else if (menuStart === "prospects")
            return <Redirect to={"/data/marketing"}/>
        else if (menuStart === "updates")
            return <Redirect to={"/extra-pages/updates"}/>
        else if (menuStart === "profile")
            return <Redirect to={"/settings"}/>
        else if (menuStart === "users")
            return <Redirect to={"/data/sales"}/>
        else if (menuStart === "orders")
            return <Redirect to={"/extra-pages/view-orders"}/>
        else if (menuStart === "logout")
            return <Redirect to={"/extra-pages/error-454"}/>
        else if (menuStart === "handbook")
            return <Redirect to={"/extra-pages/handbook"}/>
        else if (menuStart === "tool")
            return <Redirect to={"/extra-pages/translations"}/>
        else if (menuStart === "data")
            return <Redirect to={"/extra-pages/support-data"}/>
        else if (menuStart === "finance")
            return <Redirect to={"/extra-pages/support-finance"}/>
        else if (menuStart === "chat") return <Redirect to={"/extra-pages/chat"}/>
        else if (menuStart === "contact")
            return <Redirect to={"/extra-pages/contact"}/>
        else if (menuStart === "localseo")
            return <Redirect to={"/data/localseo"}/>
    } else if (
        authUser &&
        (location.pathname === "/signin" ||
            location.pathname === "/signup" ||
            location.pathname === "/forgot-password")
    ) {
        return <Redirect to={"/apps"}/>
    }

    return (
        <React.Fragment>
            <Switch>
                <RestrictedRoute path="/dashboard" component={Dashboards}/>
                <RestrictedRoute path="/extra-pages" component={ExtraPages}/>
                <RestrictedRoute path="/apps" component={Apps}/>
                <RestrictedRoute path="/data" component={DataSection}/>
                <RestrictedRoute path="/tools" component={Tools}/>
                <Route path="/signin" component={Login}/>
                <Route path="/signup" component={Signup}/>
                <Route path="/forgot-password" component={ForgotPassword}/>
                <RestrictedRoute path="/" component={Main}/>
            </Switch>

            {
                location.pathname !== "/signin" &&
                location.pathname !== "/signup" &&
                location.pathname !== "/forgot-password" && <TourGuide/>
            }

            <NotificationContainer/>
        </React.Fragment>
    )
}

export default Routes
