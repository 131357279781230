import enMessages from "../locales/ge_LU.json"

const geLULang = {
    messages: {
        ...enMessages
    },
    locale: "ge-LU"
}

export default geLULang
