import {
    UPDATE_FOOTER,
    UPDATE_FOOTER_TABLE_STATE,
    UPDATE_PROSPECT_CURRENT_STEP,
    UPDATE_FOOTER_ONLY_PROSPECT
} from "../../@jumbo/constants/ActionTypes"

// SET all footer data
export const setFooter = footerData => {
    return dispatch => {
        dispatch(
            {
                type: UPDATE_FOOTER,
                payload: footerData
            }
        )
    }
}

// SET only prospects related data
export const setFooterOnlyProspect = prospectData => {
    return dispatch => {
        dispatch(
            {
                type: UPDATE_FOOTER_ONLY_PROSPECT,
                payload: prospectData
            }
        )
    }
}

export const setFooterTableState = (value) => {
    return dispatch => {
        dispatch(
            {
                type: UPDATE_FOOTER_TABLE_STATE,
                payload: value
            }
        )
    }
}

export const setProspectCurrentStep = (step) => {
    return dispatch => {
        dispatch(
            {
                type: UPDATE_PROSPECT_CURRENT_STEP,
                payload: step
            }
        )
    }
}
