import React from "react"
import {Box} from "@material-ui/core"
import {NavLink} from "react-router-dom"
import CmtImage from "../../../../@coremat/CmtImage"
import {useSelector} from "react-redux"
import JSON5 from "json5"

const FooterLogo = ({color, ...props}) => {
    const {authUser} = useSelector(({auth}) => auth)

    let settings = JSON5.parse(authUser["settings"])
    const logoPath = settings["logo"]

    const logoUrl =
        color === "white"
            ? `/branding/${logoPath}/logo-white-symbol.png`
            : `/branding/${logoPath}/footer-logo.png`

    return (
        <Box className="pointer" {...props}>
            <NavLink to="/">
                <CmtImage src={logoUrl} alt="logo"/>
            </NavLink>
        </Box>
    )
}

export default FooterLogo
