import React from "react"
import JSON5 from "json5"
import {nanoid} from "nanoid"
import PropTypes from "prop-types"
import {Tooltip} from "@material-ui/core"
import Hidden from "@material-ui/core/Hidden"
import {lighten} from "@material-ui/core/styles"
import {useSelector, useDispatch} from "react-redux"
import IntlMessages from "../../../utils/IntlMessages"
import {useHistory, useLocation} from "react-router-dom"
import {Box, Button, makeStyles} from "@material-ui/core"
import handleFooterData from "../../../../services/common"
import {THEME_TYPES} from "../../../constants/ThemeOptions"
import ProspectsAPI from "../../../../services/api/prospects"
import {getInternalURLFromMenuOption} from "../../../../utils"
import CircularProgress from "@material-ui/core/CircularProgress"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import {setFooterTableState} from "../../../../redux/actions/Footer"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import AppContext from "../../contextProvider/AppContextProvider/AppContext"
import {setDynamicNavigation, setShowIFrame} from "../../../../redux/actions/Navigation"
import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core"

import {
    Facebook,
    Twitter,
    LinkedIn,
    Instagram,
    Pinterest,
    YouTube
} from "@material-ui/icons"

// Local Imports
import FooterLogo from "./FooterLogo"
import FooterBadge from "./FooterBadge"
import {setPaymentButtonID} from "../../../../redux/actions/prospects"

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between"
    },
    tableContainerRoot: {
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
    btnRoot: {
        [theme.breakpoints.down("xs")]: {
            padding: "6px 12px",
            fontSize: 10
        }
    },
    expandSummaryText: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            marginRight: 5,
        },
        "&:hover": {
            cursor: "pointer"
        },
        position: 'relative',
        marginLeft: "10px",
        marginRight: "10px"
    },
    parentBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "10px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        }
    },
    childBox: {
        [theme.breakpoints.down("xs")]: {
            marginBottom: "20px"
        }
    },
    onBlur: {
        pointerEvents: "none",
        opacity: 0.5
    },
    footerLogoContainer: {
        width: "400px",
        height: "auto",
        display: "flex",
        flexDirection: "column"
    },
    logoContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 30
    },
    textContainer: {
        marginBottom: 15
    },
    socialMediaContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    }
}))

const useRowStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        }
    },
    parentBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: "10px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        }
    },
    childBox: {
        [theme.breakpoints.down("xs")]: {
            marginBottom: "20px"
        }
    },
    btnRoot: {
        [theme.breakpoints.down("xs")]: {
            padding: "6px 12px",
            fontSize: 10
        }
    },
    disabledPayButton: {}
}))

const Footer = (props) => {
    const classes = useStyles()

    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const {themeType} = React.useContext(AppContext)

    const {paymentButtonID} = useSelector(({prospect}) => prospect)
    const {authUser, userToken, orderID} = useSelector(({auth}) => auth)
    const {footer, openTable, currentStep,} = useSelector(({footer}) => footer)

    const [showLeftFooter, setShowLeftFooter] = React.useState(false)
    const [showRightFooter, setShowRightFooter] = React.useState(false)
    const [showRightFooterDetails, setShowRightFooterDetails] = React.useState(false)

    const [footerLogoText, setFooterLogoText] = React.useState("")
    const [footerText, setFooterText] = React.useState("")
    const [prospectButtonText, setProspectButtonText] = React.useState(footer.prospect.buttonText ? footer.prospect.buttonText : "")
    const [prospectButtonURL, setProspectButtonURL] = React.useState(footer.prospect.buttonURL ? footer.prospect.buttonURL : "")

    const [showCircularProgress, setCircularProgress] = React.useState(false)

    const [tableData, setTableData] = React.useState({
            name: "Selectie",
            footerValue: footer.footerValue,
            data: []
        }
    )

    let footerSocialMedia = []
    let settings = JSON5.parse(authUser.settings)
    if ("footer_socials" in settings)
        footerSocialMedia = settings["footer_socials"]

    const [showOnlyCart, setShowOnlyCart] = React.useState("prospects_rightFooterCartOnly" in settings && settings["prospects_rightFooterCartOnly"] === true)

    const [prospectsFooterLocalisation, setProspectsFooterLocalisation] = React.useState(null)
    React.useEffect(() => {
        if (authUser && authUser.settings) {
            let settings = JSON5.parse(authUser.settings)
            if ("prospects_localisation_new" in settings)
                setProspectsFooterLocalisation(settings["prospects_localisation_new"])
            else if ("prospects_localisation" in settings)
                setProspectsFooterLocalisation(settings["prospects_localisation"])

            setShowOnlyCart("prospects_rightFooterCartOnly" in settings && settings["prospects_rightFooterCartOnly"] === true)
        }
    }, [authUser, authUser.settings])

    React.useEffect(() => {
        if (currentStep !== "step_7") {
            let orderButtonText = ""

            if (footer.prospect.buttonText)
                orderButtonText = footer.prospect.buttonText
            else if (prospectsFooterLocalisation)
                orderButtonText = prospectsFooterLocalisation["tra_801"]

            setProspectButtonText(orderButtonText)
        } else {
            if (parseFloat(footer.prospect.count) > 0 && footer.enablePayButton) {
                let payButtonText = prospectsFooterLocalisation["tra_802"]
                setProspectButtonText(payButtonText)
            }
        }
    }, [currentStep, footer.enablePayButton, footer.prospect.buttonText, footer.prospect.count, prospectsFooterLocalisation])

    React.useEffect(() => {
        setFooterLogoText(footer["footerLogoText"])
        setFooterText(footer["footerText"])

        let buttonURL = footer.prospect.buttonURL
        if (buttonURL.startsWith("http://") || buttonURL.startsWith("https://"))
            setProspectButtonURL(buttonURL)
        else setProspectButtonURL("")

        let payButtonText = settings["prospect_footer_pay_txt"]
        if (currentStep === "step_7" && footer.enablePayButton)
            setProspectButtonText(payButtonText)
        else setProspectButtonText(footer.prospect.buttonText)
    }, [footer])

    React.useEffect(() => {
        if (authUser && authUser.settings) {
            let settings = JSON5.parse(authUser.settings)

            let leftFooter = "leftFooter" in settings ? settings["leftFooter"] : false
            let rightFooter = "rightFooter" in settings ? settings["rightFooter"] : false
            let rightFooterDetails = "rightFooterDetails" in settings ? settings["rightFooterDetails"] : false
            let prospectsLeftFooter = "prospects_leftFooter" in settings ? settings["prospects_leftFooter"] : false
            let prospectsRightFooter = "prospects_rightFooter" in settings ? settings["prospects_rightFooter"] : false
            let prospectsRightFooterDetails = "prospects_rightFooterDetails" in settings ? settings["prospects_rightFooterDetails"] : false

            if (location.pathname === "/data/prospects") {
                setShowLeftFooter(prospectsLeftFooter)
                setShowRightFooter(prospectsRightFooter)
                setShowRightFooterDetails(prospectsRightFooterDetails)
            } else {
                setShowLeftFooter(leftFooter)
                setShowRightFooter(rightFooter)
                setShowRightFooterDetails(rightFooterDetails)
            }
        }
    }, [authUser, authUser.settings, location.pathname])

    const handleDynamicNavigation = () => {
        if (currentStep === "step_7")
            dispatch(setPaymentButtonID((new Date().getTime()).toString() + nanoid(10)))
        else {
            let menuGrouping = settings["menuGrouping"]
            let menuActive = settings["menuActive"]

            let pageInMenuGroupOptions = false

            let buttonURL = footer.prospect.buttonURL
            let page = ""
            let step = ""

            if (buttonURL.includes("|"))
                [page, step] = buttonURL.split("|")
            else page = buttonURL

            menuGrouping.forEach((group) => {
                if (group["options"].includes(page))
                    pageInMenuGroupOptions = true
            })

            if (pageInMenuGroupOptions) {
                if (Object.keys(menuActive).includes(page)) {
                    if (step)
                        history.push(`${getInternalURLFromMenuOption(page)}#${step}`)
                    else history.push(getInternalURLFromMenuOption(page))
                }
            } else {
                if (buttonURL.startsWith("<iframe")) {
                    dispatch(setDynamicNavigation({
                        "type": "iframe",
                        "value": buttonURL
                    }))
                    dispatch(setShowIFrame(true))
                }
            }
        }
    }

    const getDetailsUpdatePrice = () => {
        if (currentStep) {
            setCircularProgress(true)

            ProspectsAPI
                .updatePrice(userToken, orderID, "full", currentStep)
                .then((data) => {
                    let footer = data["order"]["footer"]

                    let footerData = {
                        footerLogoText: "footer_logo_text" in footer ? footer["footer_logo_text"] : "",
                        footerText: "footer_text" in footer ? footer["footer_text"] : "",
                        prospectButtonText: "footer_button_text" in footer ? footer["footer_button_text"] : "",
                        prospectButtonURL: "footer_button_url" in footer ? footer["footer_button_url"] : "",
                        footerValue: "footer_value" in footer ? footer["footer_value"] : "",
                        footerButtonMsg: "footer_button_msg" in footer ? footer["footer_button_msg"] : ""
                    }

                    let overview = data["order"]["overview"]
                    let _data = []

                    if ("ov_tel" in overview)
                        _data.push({key: prospectsFooterLocalisation["tra_805"], value: overview["ov_tel"]})
                    if ("ov_email" in overview)
                        _data.push({key: prospectsFooterLocalisation["tra_806"], value: overview["ov_email"]})
                    if ("ov_url" in overview)
                        _data.push({key: prospectsFooterLocalisation["tra_807"], value: overview["ov_url"]})
                    if ("ov_dir" in overview)
                        _data.push({key: prospectsFooterLocalisation["tra_808"], value: overview["ov_dir"]})
                    if ("ov_fte" in overview)
                        _data.push({key: prospectsFooterLocalisation["tra_809"], value: overview["ov_fte"]})

                    setTableData({
                        name: "Selectie",
                        footerValue: footerData.footerValue,
                        data: _data
                    })

                    handleFooterData(data["order"]["footer"], dispatch, null)
                    setCircularProgress(false)
                })
        }
    }

    function Row(props) {
        const classes = useRowStyles()
        const {row} = props

        return (
            <>
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px 0px 10px 0px",
                        minWidth: 320
                    }}
                >
                    <Box>
                        {
                            showOnlyCart === false &&
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => dispatch(setFooterTableState(!openTable))}
                            >
                                {
                                    openTable ?
                                        <KeyboardArrowUpIcon/>
                                        :
                                        <KeyboardArrowDownIcon
                                            onClick={() => getDetailsUpdatePrice()}
                                        />
                                }
                            </IconButton>
                        }
                        <FooterBadge content={footer.prospect.count} style={{marginRight: 5, marginLeft: 5}}/>
                    </Box>
                    {
                        showOnlyCart === false &&
                        <>
                            <b style={{marginRight: 5, marginLeft: 5}}>
                                {footer.prospect.amountEx}
                            </b>
                            {
                                (prospectButtonText || prospectButtonURL) ?
                                    prospectButtonURL.length > 0 ?
                                        <Box className={classes.childBox} style={{marginLeft: 5}}>
                                            <Button
                                                className={classes.btnRoot}
                                                color="primary"
                                                variant="contained"
                                                href={prospectButtonURL}
                                                target="_blank"
                                            >
                                                {prospectButtonText}
                                            </Button>
                                        </Box>
                                        :
                                        <Box className={classes.childBox} style={{marginLeft: 5}}>
                                            <Button
                                                className={classes.btnRoot}
                                                color="primary"
                                                variant="contained"
                                                onClick={handleDynamicNavigation}
                                                target="_blank"
                                            >
                                                {prospectButtonText}
                                            </Button>
                                        </Box>
                                    : null
                            }
                        </>
                    }
                </Box>
                <TableRow className={classes.root}>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={2}>
                        <Collapse in={openTable} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                    <TableRow>
                                        <TableCell>
                                            <b>
                                                {prospectsFooterLocalisation["tra_803"]}
                                            </b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>
                                                {footer.prospect.amountVAT}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <b>
                                                {prospectsFooterLocalisation["tra_800"]}
                                            </b>
                                        </TableCell>
                                        <TableCell align="right">
                                            <b>
                                                {footer.prospect.amountInc}
                                            </b>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                {
                                    showRightFooterDetails &&
                                    <React.Fragment>
                                        <Typography variant="h6" gutterBottom component="div">
                                            <p style={{color: `${settings["themeColor"]["secondary"]}`}}>
                                                {prospectsFooterLocalisation["tra_804"]}
                                            </p>
                                        </Typography>
                                        <Table size="small" aria-label="purchases">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <IntlMessages id={"footer.table.key.field"}/>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <IntlMessages id={"footer.table.key.number"}/>
                                                        {
                                                            showCircularProgress && <CircularProgress/>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.data.map(historyRow => (
                                                    <TableRow key={historyRow.key}>
                                                        <TableCell component="th" scope="row">
                                                            {/*<IntlMessages id={historyRow.key}/>*/}
                                                            {historyRow.key}
                                                        </TableCell>
                                                        <TableCell align="right">{historyRow.value}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </React.Fragment>
                                }
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    }

    Row.propTypes = {
        row: PropTypes.shape({
            name: PropTypes.string.isRequired,
            footerValue: PropTypes.string.isRequired,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                }),
            ).isRequired
        }).isRequired,
    }

    return (
        <Box className={paymentButtonID.length ? `${classes.root} ${classes.onBlur}` : classes.root} {...props}>
            <Box display="flex" alignItems="center">
                <Hidden xsDown>
                    {
                        showLeftFooter ?
                            <Box className={classes.footerLogoContainer}>
                                <Box className={classes.logoContainer}>
                                    <FooterLogo
                                        mr={5}
                                        color={themeType === THEME_TYPES.DARK ? "white" : ""}
                                    />
                                    <Box fontSize={{xs: 12, sm: 14}} component="p" color="text.disabled">
                                        <div dangerouslySetInnerHTML={{__html: footerLogoText}}/>
                                    </Box>
                                </Box>
                                {
                                    openTable ?
                                        <>
                                            <Box className={classes.textContainer}>
                                                <div dangerouslySetInnerHTML={{__html: footerText}}/>
                                            </Box>
                                            <Box className={classes.socialMediaContainer}>
                                                {
                                                    footerSocialMedia.map(media => {
                                                        return <Tooltip title={media["hover"]} placement="top">
                                                            <a
                                                                href={media["url"]}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{margin: "5px 10px 5px 0px"}}
                                                            >
                                                                {
                                                                    media["icon"] === "facebook" ?
                                                                        <Facebook
                                                                            htmlColor="black"
                                                                        />
                                                                        :
                                                                        media["icon"] === "twitter" ?
                                                                            <Twitter
                                                                                htmlColor="black"
                                                                            />
                                                                            :
                                                                            media["icon"] === "linkedin" ?
                                                                                <LinkedIn
                                                                                    htmlColor="black"
                                                                                />
                                                                                :
                                                                                media["icon"] === "instagram" ?
                                                                                    <Instagram
                                                                                        htmlColor="black"
                                                                                    />
                                                                                    : media["icon"] === "pinterest" ?
                                                                                        <Pinterest
                                                                                            htmlColor="black"
                                                                                        />
                                                                                        : media["icon"] === "youtube" ?
                                                                                            <YouTube
                                                                                                htmlColor="black"
                                                                                            />
                                                                                            : null
                                                                }
                                                            </a>
                                                        </Tooltip>
                                                    })
                                                }
                                            </Box>
                                        </> : null
                                }
                            </Box> : null
                    }
                </Hidden>
            </Box>

            {
                showRightFooter && prospectsFooterLocalisation ?
                    <Box display="flex" alignItems="center">
                        <Table
                            aria-label="collapsible table"
                        >
                            <TableBody>
                                <Row key={tableData.name} row={tableData}/>
                            </TableBody>
                        </Table>
                    </Box> : null
            }
        </Box>
    )
}

export default Footer
