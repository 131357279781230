import enLang from "./entries/en-US"
import arLang from "./entries/ar_SA"
import itLang from "./entries/it_IT"
import esLang from "./entries/es_ES"
import frLang from "./entries/fr_FR"
import nlLang from "./entries/nl_NL"
import nlEnLang from "./entries/en_NL"
import beEnLang from "./entries/en_BE"
import nlBELang from "./entries/nl_BE"
import frBELang from "./entries/fr_BE"
import frLULang from "./entries/fr_LU"
import geLULang from "./entries/ge_LU"
import zhLang from "./entries/zh-Hans-CN"

const AppLocale = {
    en: enLang,
    zh: zhLang,
    ar: arLang,
    it: itLang,
    es: esLang,
    fr: frLang,
    nl: nlLang,
    nl_NL: nlLang,
    en_NL: nlEnLang,
    en_BE: beEnLang,
    nl_BE: nlBELang,
    fr_BE: frBELang,
    fr_LU: frLULang,
    ge_LU: geLULang
}

export default AppLocale
