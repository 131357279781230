import enMessages from "../locales/en_US.json"

const beEnLang = {
    messages: {
        ...enMessages
    },
    locale: "en-BE"
}

export default beEnLang
