import React from "react"
import Routes from "./routes"
import {Provider} from "react-redux"
import {Switch} from "react-router-dom"
import "react-perfect-scrollbar/dist/css/styles.css"
import configureStore, {history} from "./redux/store"
import {ConnectedRouter} from "connected-react-router"
import AppWrapper from "./@jumbo/components/AppWrapper"
import AppContextProvider from "./@jumbo/components/contextProvider/AppContextProvider"

export const store = configureStore()

const App = () => (
    <Provider store={store}>
        <AppContextProvider>
            <ConnectedRouter history={history}>
                <AppWrapper>
                    <Switch>
                        <Routes/>
                    </Switch>
                </AppWrapper>
            </ConnectedRouter>
        </AppContextProvider>
    </Provider>
)

export default App
