import makeStyles from "@material-ui/core/styles/makeStyles";


const NotificationStyles = makeStyles({
    "@global": {
        ".notification-container": {
            marginTop: props => `${props.marginTop}px !important`
        }
    }
})

export default NotificationStyles
