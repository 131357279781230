import enMessages from "../locales/fr_BE.json"

const frBELang = {
    messages: {
        ...enMessages
    },
    locale: "fr-BE"
}

export default frBELang
