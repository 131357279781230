import {
    UPDATE_DYNAMIC_NAVIGATION,
    UPDATE_SHOW_IFRAME
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
    dynamicNavigation: {
        navigationType: "", // external_url/iframe/menu/in_page_navigation
        value: ""
    },
    showIFrame: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_DYNAMIC_NAVIGATION: {
            return {
                ...state,
                dynamicNavigation: {
                    navigationType: action.payload["type"],
                    value: action.payload["value"]
                }
            }
        }
        case UPDATE_SHOW_IFRAME: {
            return {
                ...state,
                showIFrame: action.payload
            }
        }
        default:
            return state
    }
}
