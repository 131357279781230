import {
    UPDATE_SETTINGS,
    UPDATE_AUTH_USER,
    UPDATE_LOAD_USER,
    UPDATE_AUTH_WEBSITE,
    UPDATE_AUTH_USER_ONLY,
    UPDATE_AUTH_USER_TOKEN,
    UPDATE_AUTH_USER_ORDER_ID,
    SEND_FORGET_PASSWORD_EMAIL,
    UPDATE_INITIAL_ORDER_CONFIG
} from "../../@jumbo/constants/ActionTypes"

const INIT_STATE = {
    authUser: null,
    loadUser: false,
    send_forget_password_email: false,
    userToken: null,
    orderID: null,
    initialOrderConfig: null,
    website: "",
    baseWebsite: null,
    settings: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_AUTH_USER: {
            return {
                ...state,
                authUser: action.payload["authData"],
                userToken: action.payload["token"],
                orderID: action.payload["orderID"],
                initialOrderConfig: action.payload["config"],
                loadUser: true,
                baseWebsite: action.payload["baseWebsite"],
                settings: action.payload["settings"]
            }
        }
        case UPDATE_SETTINGS: {
            return {
                ...state,
                settings: action.payload
            }
        }
        case UPDATE_AUTH_USER_ONLY: {
            return {
                ...state,
                authUser: action.payload["authData"]
            }
        }
        case UPDATE_AUTH_WEBSITE: {
            return {
                ...state,
                website: action.payload
            }
        }
        case UPDATE_LOAD_USER: {
            return {
                ...state,
                loadUser: action.payload
            }
        }
        case SEND_FORGET_PASSWORD_EMAIL: {
            return {
                ...state,
                send_forget_password_email: action.payload
            }
        }
        case UPDATE_AUTH_USER_TOKEN: {
            return {
                ...state,
                userToken: action.payload
            }
        }
        case UPDATE_AUTH_USER_ORDER_ID: {
            return {
                ...state,
                orderID: action.payload
            }
        }
        case UPDATE_INITIAL_ORDER_CONFIG: {
            return {
                ...state,
                initialOrderConfig: action.payload
            }
        }
        default:
            return state
    }
}
