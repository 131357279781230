import {
    ADD_LABEL,
    SET_ERROR_MSG,
    UPDATE_CONTACT,
    CREATE_CONTACT,
    DELETE_CONTACT,
    SET_LABELS_LIST,
    SET_FILTER_TYPE,
    SET_CURRENT_NOTE,
    SET_FOLDERS_LIST,
    DELETE_LABEL_ITEM,
    SET_CONTACTS_LIST,
    UPDATE_LABEL_ITEM,
    SET_ITEMS_PER_PAGE,
    GET_CONTACT_COUNTS,
    SET_CURRENT_CONTACT,
    UPDATE_CONTACT_LABEL,
    UPDATE_STARRED_STATUS,
    SET_CURRENT_SELECTION,
    SET_CURRENT_PAGE_NUMBER,
    TOGGLE_SIDEBAR_COLLAPSED,
    SET_PAGINATION_PAGE_COUNT,
    UPDATE_CHECKED_LOCALSEO_ACCOUNT_IDS
} from "../../@jumbo/constants/ActionTypes"

const INIT_STATE = {
    isSideBarCollapsed: false,
    filterType: {
        selectedFolder: "active",
        selectedLabel: "",
        searchText: ""
    },
    contactsList: [],
    foldersList: [],
    labelsList: [],
    checkedLocalSEOAccountIds: [],
    currentContact: null,
    totalContacts: null,
    counter: null,
    currentPageNumber: 1,
    itemsPerPage: 6,
    totalPaginationPages: 0,
    errorMsg: {
        errorText: "",
        showError: false
    },
    currentNote: null,
    currentSelection: null
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR_COLLAPSED: {
            return {
                ...state,
                isSideBarCollapsed: action.payload
                    ? action.payload
                    : !state.isSideBarCollapsed
            }
        }
        case SET_ERROR_MSG: {
            return {
                ...state,
                errorMsg: action.payload
            }
        }
        case SET_FILTER_TYPE: {
            return {
                ...state,
                filterType: action.payload
            }
        }
        case SET_FOLDERS_LIST: {
            return {
                ...state,
                foldersList: action.payload
            }
        }
        case SET_PAGINATION_PAGE_COUNT: {
            return {
                ...state,
                totalPaginationPages: action.payload
            }
        }
        case SET_LABELS_LIST: {
            return {...state, labelsList: action.payload};
        }
        case ADD_LABEL: {
            return {
                ...state,
                labelsList: state.labelsList.concat(action.payload)
            }
        }
        case UPDATE_LABEL_ITEM: {
            return {
                ...state,
                labelsList: state.labelsList.map(
                    item => (item.id === action.payload.id ? action.payload : item)
                )
            }
        }
        case DELETE_LABEL_ITEM: {
            return {
                ...state,
                labelsList: state.labelsList.filter(item => item.id !== action.payload)
            }
        }
        case SET_CONTACTS_LIST: {
            return {
                ...state,
                contactsList: action.payload
            }
        }
        case SET_CURRENT_CONTACT: {
            return {
                ...state,
                currentContact: action.payload
            }
        }
        case SET_CURRENT_SELECTION: {
            return {
                ...state,
                currentSelection: action.payload
            }
        }
        case CREATE_CONTACT: {
            let updatedList = state.contactsList
            let updatedCount = state.totalContacts
            if (state.filterType.selectedFolder === "contacts") {
                updatedList = [action.payload, ...updatedList]
                updatedCount = updatedCount + 1
            }
            return {
                ...state,
                contactsList: updatedList,
                totalContacts: updatedCount
            }
        }
        case UPDATE_CONTACT: {
            return {
                ...state,
                contactsList: state.contactsList.map(
                    item => (item.id === action.payload.id ? action.payload : item)
                )
            }
        }
        case UPDATE_STARRED_STATUS: {
            const {contactIds, status} = action.payload

            let updatedList = state.contactsList.map(contact => {
                if (contactIds.includes(contact.id)) {
                    contact.starred = status
                    return contact
                }
                return contact
            })

            if (!status && state.filterType.selectedFolder === "starred") {
                updatedList = updatedList.filter(item => !contactIds.includes(item.id))
            }

            return {
                ...state,
                contactsList: updatedList
            }
        }
        case DELETE_CONTACT: {
            let updatedList = state.contactsList

            if (state.filterType.selectedFolder === "inactive") {
                updatedList = updatedList.filter(
                    contact => action.payload !== contact.id
                )
            }

            return {
                ...state,
                contactsList: updatedList
            }
        }
        case SET_CURRENT_PAGE_NUMBER: {
            return {
                ...state,
                currentPageNumber: action.payload
            }
        }
        case SET_ITEMS_PER_PAGE: {
            return {
                ...state,
                itemsPerPage: action.payload
            }
        }
        case UPDATE_CONTACT_LABEL: {
            let contactIds = action.payload.map(contact => contact.id)
            const updatedList = state.contactsList.map(mail => {
                if (contactIds.includes(mail.id)) {
                    return action.payload.find(
                        selectedContact => selectedContact.id === mail.id
                    )
                } else {
                    return mail
                }
            })

            return {
                ...state,
                contactsList: updatedList
            }
        }
        case GET_CONTACT_COUNTS: {
            return {
                ...state,
                counter: action.payload
            }
        }
        case SET_CURRENT_NOTE: {
            return {
                ...state,
                currentNote: action.payload
            }
        }
        case UPDATE_CHECKED_LOCALSEO_ACCOUNT_IDS: {
            return {
                ...state,
                checkedLocalSEOAccountIds: action.payload
            }
        }
        default:
            return state
    }
}
