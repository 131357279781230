import {
    MOVE_NEXT_TAB,
    MOVE_PREVIOUS_TAB,
    UPDATE_STEP1_DATA,
    UPDATE_STEP2_DATA,
    UPDATE_STEP3_DATA,
    UPDATE_STEP4_DATA,
    UPDATE_STEP5_DATA,
    UPDATE_STEP6_DATA,
    UPDATE_STEP7_DATA,
    UPDATE_STEP8_DATA,
    UPDATE_STEP9_DATA,
    UPDATE_STEP11_DATA,
    UPDATE_STEP10_DATA,
    UPDATE_STEP5_MARKERS,
    UPDATE_SNACKBAR_ALERT,
    UPDATE_STEP7_FORM_DATA,
    UPDATE_PAYMENT_BUTTON_ID,
    UPDATE_STEP5_ALL_LOCATIONS,
    UPDATE_STEP5_SELECTED_COUNTRY
} from "../../@jumbo/constants/ActionTypes"

const INIT_STATE = {
    step1Data: null,
    step2Data: null,
    step3Data: null,
    step4Data: null,
    step5Data: {},
    step5DATA: {
        provinces: null,
        markers: [],
        localCache: {
            allLocations: null,
            selectedCountryCode: null
        },
        allLocations: null,
        selectedCountryCode: null,
        settings: null,
        GroupList: null
    },
    step5AllLocations: null,
    step5Markers: [],
    step5SelectedCountryCode: null,
    step6Data: null,
    step7Data: null,
    step8Data: null,
    step9Data: null,
    step10Data: null,
    step11DATA: [],
    formData: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        company: "",
        street: "",
        houseNumber: "",
        postCode: "",
        location: "",
        reference: "",
        organisation_id: "",
        radio1: "0",
        radio2: "0"
    },
    paymentButtonID: "",
    localCacheSTEP5: {
        allLocations: null,
        selectedCountryCode: null
    },
    moveNextTab: false,
    movePreviousTab: false,
    settings: [],
    snackbarAlert: {
        open: false,
        severity: "info",
        alertText: "",
        duration: 3000
    }
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_STEP1_DATA: {
            return {
                ...state,
                step1Data: action.payload
            }
        }
        case UPDATE_STEP2_DATA: {
            return {
                ...state,
                step2Data: action.payload
            }
        }
        case UPDATE_STEP3_DATA: {
            return {
                ...state,
                step3Data: action.payload
            }
        }
        case UPDATE_STEP4_DATA: {
            return {
                ...state,
                step4Data: action.payload
            }
        }
        case UPDATE_STEP5_DATA: {
            return {
                ...state,
                step5DATA: action.payload
            }
        }
        case UPDATE_STEP5_MARKERS: {
            return {
                ...state,
                step5Markers: action.payload
            }
        }
        case UPDATE_STEP5_ALL_LOCATIONS: {
            return {
                ...state,
                step5DATA: {
                    ...state.step5DATA,
                    allLocations: action.payload
                }
            }
        }
        case UPDATE_STEP6_DATA: {
            return {
                ...state,
                step6Data: action.payload
            }
        }
        case UPDATE_STEP7_DATA: {
            return {
                ...state,
                step7Data: action.payload
            }
        }
        case UPDATE_STEP8_DATA: {
            return {
                ...state,
                step8Data: action.payload
            }
        }
        case UPDATE_STEP9_DATA: {
            return {
                ...state,
                step9Data: action.payload
            }
        }
        case UPDATE_STEP10_DATA: {
            return {
                ...state,
                step10Data: action.payload
            }
        }
        case UPDATE_STEP11_DATA: {
            return {
                ...state,
                step11DATA: action.payload
            }
        }
        case UPDATE_STEP5_SELECTED_COUNTRY: {
            return {
                ...state,
                step5DATA: {
                    ...state.step5DATA,
                    selectedCountryCode: action.payload
                }
            }
        }
        case UPDATE_STEP7_FORM_DATA: {
            return {
                ...state,
                formData: action.payload
            }
        }
        case UPDATE_PAYMENT_BUTTON_ID: {
            return {
                ...state,
                paymentButtonID: action.payload
            }
        }
        case MOVE_NEXT_TAB: {
            return {
                ...state,
                moveNextTab: action.payload
            }
        }
        case MOVE_PREVIOUS_TAB: {
            return {
                ...state,
                movePreviousTab: action.payload
            }
        }
        case UPDATE_SNACKBAR_ALERT: {
            return {
                ...state,
                snackbarAlert: {
                    ...state.snackbarAlert,
                    ...action.payload
                }
            }
        }
        default:
            return state
    }
}
