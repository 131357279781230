import enMessages from "../locales/nl_BE.json"

const nlBELang = {
    messages: {
        ...enMessages
    },
    locale: "nl-BE"
}

export default nlBELang
