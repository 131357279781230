import axios from "axios"

const url = new URL(window.location.href)
const user_api = url.searchParams.get("user_api") === null ? "" : url.searchParams.get("user_api")
const [baseURL, file] = user_api.split("/")

export default axios.create({
    baseURL: `https://${baseURL}`,
    url: file,
    headers: {
        "Content-Type": "application/json"
    }
})



