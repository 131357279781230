import axios from "axios"
import {
    ADD_LABEL,
    SET_ERROR_MSG,
    MOVE_NEXT_TAB,
    CREATE_CONTACT,
    DELETE_CONTACT,
    UPDATE_CONTACT,
    SET_LABELS_LIST,
    SET_FILTER_TYPE,
    SET_CURRENT_NOTE,
    SET_FOLDERS_LIST,
    DELETE_LABEL_ITEM,
    SET_CONTACTS_LIST,
    UPDATE_LABEL_ITEM,
    SET_ITEMS_PER_PAGE,
    GET_CONTACT_COUNTS,
    SET_CURRENT_CONTACT,
    UPDATE_CONTACT_LABEL,
    UPDATE_STARRED_STATUS,
    SET_CURRENT_SELECTION,
    SET_CURRENT_PAGE_NUMBER,
    TOGGLE_SIDEBAR_COLLAPSED,
    SET_PAGINATION_PAGE_COUNT,
    UPDATE_CHECKED_LOCALSEO_ACCOUNT_IDS
} from "../../@jumbo/constants/ActionTypes"
import {fetchError, fetchStart, fetchSuccess} from "./Common"


export const toggleExpandSidebar = value => {
    return dispatch => {
        dispatch({
            type: TOGGLE_SIDEBAR_COLLAPSED,
            payload: value
        })
    }
}

export const setFilterType = filterType => {
    return dispatch => {
        dispatch({
            type: SET_FILTER_TYPE,
            payload: filterType
        })
    }
}

export const setErrorMsg = error => {
    return dispatch => {
        dispatch({
            type: SET_ERROR_MSG,
            payload: error
        })
    }
}

export const setCurrentPageNumber = page_number => {
    return dispatch => {
        dispatch({
            type: SET_CURRENT_PAGE_NUMBER,
            payload: page_number
        })
    }
}

export const setItemsPerPage = count => {
    return dispatch => {
        dispatch({
            type: SET_ITEMS_PER_PAGE,
            payload: count
        })
    }
}

export const setPaginationPageCount = count => {
    return dispatch => {
        dispatch({
            type: SET_PAGINATION_PAGE_COUNT,
            payload: count
        })
    }
}

export const setFoldersList = data => {
    return dispatch => {
        dispatch({
            type: SET_FOLDERS_LIST,
            payload: data
        })
    }
}

export const setLabelsList = (data) => {
    return dispatch => {
        dispatch({
            type: SET_LABELS_LIST,
            payload: data
        })
    }
}

export const addNewLabel = label => {
    return dispatch => {
        dispatch({
            type: ADD_LABEL,
            payload: label
        })
    }
}

export const updateLabel = label => {
    return dispatch => {
        dispatch({
            type: UPDATE_LABEL_ITEM,
            payload: label
        })
    }
}

export const deleteLabel = labelId => {
    return dispatch => {
        dispatch({
            type: DELETE_LABEL_ITEM,
            payload: labelId
        })
    }
}

export const setContactsList = data => {
    return dispatch => {
        dispatch({
            type: SET_CONTACTS_LIST,
            payload: data
        })
    }
}

export const setCurrentContact = contact => {
    return dispatch => {
        dispatch({
            type: SET_CURRENT_CONTACT,
            payload: contact
        })
    }
}

export const setCurrentSelection = data => {
    return dispatch => {
        dispatch({
            type: SET_CURRENT_SELECTION,
            payload: data
        })
    }
}

export const setCurrentNote = note => {
    return dispatch => {
        dispatch({
            type: SET_CURRENT_NOTE,
            payload: note
        })
    }
}

export const handleLocalSEOAccountSelection = ({selectedItem, moveToNextTab}) => {
    return dispatch => {
        dispatch({type: SET_CURRENT_SELECTION, payload: selectedItem})
        dispatch({type: MOVE_NEXT_TAB, payload: moveToNextTab})
    }
}

export const updateLocalSEOAccountCheckedIds = data => {
    return dispatch => {
        dispatch({
            type: UPDATE_CHECKED_LOCALSEO_ACCOUNT_IDS,
            payload: data
        })
    }
}

//for creating new contact
export const createContact = contact => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .post("/contact", {contact})
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({type: CREATE_CONTACT, payload: data.data});
                } else {
                    dispatch(fetchError("Something went wrong"));
                }
            })
            .catch(error => {
                dispatch(fetchError("Something went wrong"));
            });
    };
};

//for updating contact through detail page
export const onUpdateContact = contact => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put("/contact", {contact})
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({type: UPDATE_CONTACT, payload: contact});
                } else {
                    dispatch(fetchError("Something went wrong"));
                }
            })
            .catch(error => {
                dispatch(fetchError("Something went wrong"));
            });
    };
};

//for updating contacts starred status(through listing)
export const updateStarredStatus = (contactIds, status) => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put("/contact/update-starred", {contactIds, status})
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({
                        type: UPDATE_STARRED_STATUS,
                        payload: {contactIds, status}
                    });
                } else {
                    dispatch(fetchError("Something went wrong"));
                }
            })
            .catch(error => {
                dispatch(fetchError("Something went wrong"));
            });
    };
};

export const deleteContact = contactId => {
    return dispatch => {
        dispatch({
            type: DELETE_CONTACT,
            payload: contactId
        })
    }
}

//for updating contacts label(through listing)
export const updateContactsLabel = (contactIds, label) => {
    return dispatch => {
        dispatch(fetchStart());
        axios
            .put("/contact/update-label", {contactIds, label})
            .then(data => {
                if (data.status === 200) {
                    dispatch(fetchSuccess());
                    dispatch({type: UPDATE_CONTACT_LABEL, payload: data.data});
                } else {
                    dispatch(fetchError("Something went wrong"));
                }
            })
            .catch(error => {
                dispatch(fetchError("Something went wrong"));
            });
    };
};

//for getting contact categories(in sidebar) count
export const getContactCounts = () => {
    return dispatch => {
        axios
            .get("/contact/counter")
            .then(data => {
                if (data.status === 200) {
                    dispatch({type: GET_CONTACT_COUNTS, payload: data.data});
                }
            })
            .catch(error => {
                dispatch(fetchError("Something went wrong"));
            });
    };
};
