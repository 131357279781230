import React from "react";
import Hidden from "@material-ui/core/Hidden";
import {Box} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";
import {useSelector} from "react-redux";

const Logo = ({color, ...props}) => {
    const JSON5 = require('json5');

    const {authUser} = useSelector(({auth}) => auth);

    let settings = authUser['settings'];
    settings = JSON5.parse(settings);

    const logoPath = settings['logo'];

    const logoUrl =
        color === "white" ? `/branding/${logoPath}/logo-white.png` : `/branding/${logoPath}/logo.png`;
    const logoSymbolUrl =
        color === "white"
            ? `/branding/${logoPath}/logo-white-symbol.png`
            : `/branding/${logoPath}/logo-symbol.png`;

    return (
        <Box className="pointer" {...props}>
            <Hidden xsDown>
                <NavLink to="/">
                    <CmtImage src={logoUrl} alt="logo"/>
                </NavLink>
            </Hidden>
            <Hidden smUp>
                <NavLink to="/">
                    <CmtImage src={logoSymbolUrl} alt="logo"/>
                </NavLink>
            </Hidden>
        </Box>
    );
};

export default Logo;
