import {
    UPDATE_DYNAMIC_NAVIGATION,
    UPDATE_SHOW_IFRAME
} from "../../@jumbo/constants/ActionTypes"

export const setDynamicNavigation = data => {
    return dispatch => {
        dispatch(
            {
                type: UPDATE_DYNAMIC_NAVIGATION,
                payload: data
            }
        )
    }
}

export const setShowIFrame = data => {
    return dispatch => {
        dispatch(
            {
                type: UPDATE_SHOW_IFRAME,
                payload: data
            }
        )
    }
}