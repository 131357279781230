import React, {useContext} from "react";
import IconButton from "@material-ui/core/IconButton";
import LayoutContext from "./LayoutContext/LayoutContext";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const SidebarToggleHandlerMod = ({children, ...restProps}) => {
    const {isOpen, setOpen} = useContext(LayoutContext);

    return (
        <IconButton
            className="Cmt-toggle-menu"
            onClick={() => setOpen(!isOpen)}
            {...restProps}
        >
            {children || isOpen ? <ArrowBackIcon/> : <ArrowForwardIcon/>}
        </IconButton>
    );
};

export default SidebarToggleHandlerMod;
