import React, {lazy, Suspense} from "react"
import {Redirect, Route, Switch} from "react-router"
import PageLoader from "../../@jumbo/components/PageComponents/PageLoader"

const Tools = ({match}) => {
    const requestedUrl = match.url.replace(/\/$/, "")

    return (
        <Suspense fallback={<PageLoader/>}>
            <Switch>
                <Redirect
                    exact
                    from={`${requestedUrl}/`}
                    to={`${requestedUrl}/enrich`}
                />

                <Route
                    path={`${requestedUrl}/enrich`}
                    component={lazy(() => import('./Enrich'))}
                />

                <Route component={lazy(() => import("../ExtraPages/404"))}/>
            </Switch>
        </Suspense>
    )
}

export default Tools
